import { TableauMappingAccommodationTableauNumbers } from '../../../../models';

export function filterRoomLabels(
  accommodationsIds: number[],
  room_labels: {
    [accommodationId: string]: TableauMappingAccommodationTableauNumbers[];
  },
): TableauMappingAccommodationTableauNumbers[] {
  if (!accommodationsIds.length) {
    return [];
  }

  return accommodationsIds.reduce(
    (
      filteredRoomLabels: TableauMappingAccommodationTableauNumbers[],
      accommodation_id,
    ) => {
      return [...filteredRoomLabels, ...room_labels?.[accommodation_id]];
    },
    [],
  );
}
