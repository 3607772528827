import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CityTaxConfigurationFieldsFormValue,
  CityTaxOptionFormType,
  CityTaxOptionsFieldsFormValue,
  CityTaxRestrictionForm,
} from '../../../../models';
import { inject } from '@angular/core';
import { EXCLUSION_RULES } from '../constants';

export const restrictionsPatchValue = (
  restrictionsFormArray: FormArray<FormGroup<CityTaxRestrictionForm>>,
  values: CityTaxConfigurationFieldsFormValue[],
): void => {
  values = removeAgeRoles(values);

  restrictionsFormArray.clear({ emitEvent: false });

  values?.forEach((data) => {
    const form = buildRestrictionForm();

    form.patchValue(data, { emitEvent: false });

    restrictionsFormArray.push(form);
  });
};

export const optionsPatchValue = (
  exclusionsFormArray: CityTaxOptionFormType,
  values: CityTaxOptionsFieldsFormValue[],
) => {
  exclusionsFormArray.clear({ emitEvent: false });

  values?.forEach((data) => {
    const form = buildOptionsForm();

    form.patchValue(data, { emitEvent: false });

    exclusionsFormArray.push(form);
  });
};

function buildRestrictionForm() {
  const fb = inject(FormBuilder);

  return fb.group({
    city_tax_restriction_id: fb.control(null, [Validators.required]),
    fields: fb.control([]),
  });
}

function buildOptionsForm() {
  const fb = inject(FormBuilder);

  return fb.group({
    city_tax_option_id: fb.control(null, [Validators.required]),
    fields: fb.control([]),
  });
}

export function emptyRestrictionForm() {
  return buildRestrictionForm();
}

export function emptyOptionForm() {
  return buildOptionsForm();
}

function removeAgeRoles(values: CityTaxConfigurationFieldsFormValue[]) {
  return values?.filter(
    (rule) => rule.city_tax_restriction_id !== EXCLUSION_RULES.ageRange,
  );
}
