import { inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CityTaxConfigurationFieldsFormValue,
  CityTaxConfigurationForm,
  CityTaxOptionForm,
  CityTaxRestrictionForm,
} from '../../../../models';

export const getEmptyConfigurationForm =
  (): FormGroup<CityTaxConfigurationForm> => {
    const fb = inject(FormBuilder);

    return fb.group<CityTaxConfigurationForm>({
      id: fb.control(null),
      flag_year: fb.control<boolean>(null),
      property_ids: fb.control([], [Validators.required]),
      city_tax_id: fb.control(null, [Validators.required]),
      price_amount: fb.control(null, [Validators.required]),
      computation_type: fb.control('checkin'),
      price_percentage: fb.control(null),
      age_ranges: fb.control<CityTaxConfigurationFieldsFormValue[]>([]),
      parent_id: fb.control(null),
      range: fb.control([]),
      configuration_fields: fb.array<FormGroup<CityTaxRestrictionForm>>([]),
      inclusions: fb.array<FormGroup<CityTaxOptionForm>>([]),
      exclusions: fb.array<FormGroup<CityTaxOptionForm>>([]),
    });
  };
