import {
  CityTaxConfiguration,
  CityTaxConfigurationFormValue,
  CityTaxOptionsFieldsFormValue,
} from '../../../../models';
import { EXCLUSION_RULES } from '../constants';

export const parseApiConfigurationToFormConfiguration = (
  configuration: CityTaxConfiguration,
): CityTaxConfigurationFormValue => {
  if (!configuration) {
    return;
  }

  const {
    date_from,
    date_to,
    configuration_option_fields,
    properties: property_ids,
    configuration_fields,
    overrides,
  } = configuration;

  let range: [Date, Date] | [] = [];

  if (date_from && date_to) {
    range = [new Date(date_from), new Date(date_to)];
  }

  const exclusions: CityTaxOptionsFieldsFormValue[] =
    configuration_option_fields?.filter(({ action }) => action === 'exclusive');

  const inclusions: CityTaxOptionsFieldsFormValue[] =
    configuration_option_fields?.filter(({ action }) => action === 'inclusive');

  const age_ranges = configuration_fields?.filter(
    (configuration) =>
      configuration.city_tax_restriction_id === EXCLUSION_RULES.ageRange,
  );

  return {
    ...configuration,
    range,
    inclusions,
    exclusions,
    property_ids,
    age_ranges,
    overrides: overrides?.map(parseApiConfigurationToFormConfiguration),
  };
};
