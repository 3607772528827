import { reduce } from 'lodash';
import { JsonDateParser } from '../../../../helpers';

export const parseFormFieldsToApiFields = (
  formValue: Record<string, any>,
): Array<{ name: string; value: number | string }> =>
  reduce(
    JsonDateParser.toServerFormat(formValue),
    (result, value, name) => {
      result = [...result, { name, value }];

      return result;
    },
    [],
  );

export const parseApiFieldsToFormFields = (
  formValue: Array<{ name: string; value: number | string }>,
): Record<string, any> =>
  reduce(
    JsonDateParser.toDate(formValue) as Array<{
      name: string;
      value: number | string;
    }>,
    (result, value) => {
      result = {
        ...result,
        [value.name]: value.value,
      };

      return result;
    },
    {},
  );
